import {ApiIdentifiable, ApiSimpleUser} from "#/src/definitions/ApiDefinitions";
import AppConstants from "#/src/utils/app-constants";

export enum AttachmentNamespace {
  USER = "users",
  STREAMER = "streamers",
  ARTICLE = "articles",
  GAME_ICON = "game-icons",
  VIDEO = "videos",
  AD = "ads",
  DESCRIPTION_CARD = "description-card"
}

export class Attachments {
  static getUserAvatar(entity: ApiSimpleUser): string {
    return `${AppConstants.ATTACHMENT_STORAGE}/${AttachmentNamespace.USER}/${entity.id}-${entity.avatarId}`;
  }
  static getUrl(namespace: AttachmentNamespace, entity: ApiIdentifiable): string {
    return `${AppConstants.ATTACHMENT_STORAGE}/${namespace}/${entity.id}`;
  }
}